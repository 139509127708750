import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Typography, InputLabel, Button } from '@mui/material';
import Security from '../../components/Security';
import TextFieldComponent from '../../components/TextField';
import Logo from '../../assets/Images/ForgetPassword.png';
import { forgotPasswordSendEmailAction, clearError } from '../../redux/AuthDuck';
import * as ROUTES from '../../constants/routes';

const ForgetPassword = () => {

    const error = useSelector(store => store.auth.error);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const dispatch = useDispatch();

    const onChange = e => {
        const { value } = e.target;
        setEmail(value);
    };

    const sendEmail = () => {
        if (validateUserFields()) {
            dispatch(forgotPasswordSendEmailAction(email));
            setEmail('');
        }
    };

    const validateUserFields = () => {
        var error = "";

        if (email === null || email.length === 0
            || /^\s+$/.test(email))
            error = "Email is required.";
        else if (!validateUserEmail(email))
            error = "Invalid email format.";

        setEmailError(error);
        return !error;
    };

    const validateUserEmail = ((email) => {
        var regex = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        return regex.test(email) ? true : false;
    });

    useEffect(() => {
        dispatch(clearError());
    }, [dispatch]);

    return (
        <Security logo={Logo}>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <Typography className="title-app" style={{ marginBottom: '5%' }}>
                    Reset password
                </Typography>
                <Typography className="label-forget-password" style={{ marginBottom: '5%' }}>
                    In order to reset your password, please input your registered email address.
                </Typography>
                <InputLabel className="label-app">Email</InputLabel>
                <TextFieldComponent name="email"
                    valueInput={email} type="email"
                    callback={onChange.bind(this)}
                    errorInput={emailError || error}
                />
                <Button variant="contained" className="button-login" disableElevation
                    onClick={() => sendEmail()}>
                    Submit
                </Button>
                <Typography className="link-login" component={Link}
                    to={ROUTES.LOGIN}>
                    Return to login
                </Typography>
            </div>
        </Security>
    );
}

export default withRouter(ForgetPassword);