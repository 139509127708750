import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from 'react-router-dom';
import { Grid, Typography, InputLabel, Button, Container } from "@mui/material";
import TextFieldComponent from '../../components/TextField';
import Title from '../../components/Title';
import { changePasswordAction, clearError } from "../../redux/AuthDuck";
import * as ROUTES from '../../constants/routes';
import './ChangePassword.css';

const ChangePassword = props => {

    const user = useSelector(store => store.auth.user);
    const error = useSelector(store => store.auth.error);
    const [password, setPassword] = useState({
        currentPassword: '', password: '', confirmPassword: ''
    });
    const [passwordError, setPasswordError] = useState({});
    const dispatch = useDispatch();

    const onChange = e => {
        const { name, value } = e.target;
        setPassword({ ...password, [name]: value });
    };

    const onSubmit = () => {
        if (validationPassword()) {
            dispatch(changePasswordAction(password));
            setPassword({
                currentPassword: '', password: '', confirmPassword: ''
            });
        }
    };

    const validationPassword = () => {
        const passwordLength = 8;
        var error = { password: '', confirmPassword: '', currentPassword: '' };

        if (password.currentPassword === null || password.currentPassword.length === 0
            || /^\s+$/.test(password.currentPassword))
            error.currentPassword = "La contraseña actual es requerida";
        if (password.password === null || password.password.length === 0
            || /^\s+$/.test(password.password))
            error.password = "La contraseña es requerida";
        else if (password.password.length < passwordLength ||
            !/[^a-zA-Z0-9]/.test(password.password))
            error.password = "El formato de la contraseña es incorrecto, requiere al menos 8 caracteres y deben incluir números, letras mayúsculas, minúsculas y al menos 1 caracter especial";
        if (password.confirmPassword === null || password.confirmPassword.length === 0
            || /^\s+$/.test(password.confirmPassword))
            error.confirmPassword = "La contraseña es requerida";
        else if (password.confirmPassword.length < passwordLength ||
            !/[^a-zA-Z0-9]/.test(password.confirmPassword))
            error.confirmPassword = "El formato de la contraseña es incorrecto, requiere al menos 8 caracteres y deben incluir números, letras mayúsculas, minúsculas y al menos 1 caracter especial";
        if ((!error.password && !error.confirmPassword) &&
            password.password !== password.confirmPassword)
            error.confirmPassword = "Las contraseñas no coinciden";

        setPasswordError({ ...passwordError, ...error });
        return !error.password && !error.confirmPassword && !error.currentPassword;
    };

    useEffect(() => {
        dispatch(clearError());
    }, [dispatch]);

    return (
        <Container>
            <Title title='Cambiar Contraseña' style={{ marginLeft: 26 }} />
            <Grid item xs={12}>
                <div className="container-app-div">
                    <div style={{ display: 'flex', flexDirection: 'column', width: '35%' }}>
                        <form id="changePass">
                            <Typography className="label-generate-password" style={{ marginBottom: '20px' }}>
                                Generar nueva contraseña
                            </Typography>
                            <InputLabel className="label-app">Contraseña Actual</InputLabel>
                            <TextFieldComponent name="currentPassword"
                                valueInput={password.currentPassword} type="password"
                                callback={onChange.bind(this)}
                                errorInput={passwordError.currentPassword || error}
                            />
                            <InputLabel className="label-app">Nueva Contraseña</InputLabel>
                            <TextFieldComponent name="password"
                                valueInput={password.password} type="password"
                                callback={onChange.bind(this)}
                                errorInput={passwordError.password}
                            />
                            <InputLabel className="label-app">Confirmar Nueva Contraseña</InputLabel>
                            <TextFieldComponent name="confirmPassword"
                                valueInput={password.confirmPassword} type="password"
                                callback={onChange.bind(this)}
                                errorInput={passwordError.confirmPassword}
                            />
                        </form>
                    </div>
                </div>
                <div style={{ display: 'flex' }}>
                    <Button variant="contained" className="button-app-save" style={{ marginRight: 18 }}
                        onClick={() => onSubmit()}>
                        Cambiar
                    </Button>
                    {(user && !user.temporaryPasswordLogin) &&
                        <Button variant="contained" className="button-app-cancel"
                            onClick={() => props.history.push(ROUTES.CLIENT)}>
                            Cancelar
                        </Button>
                    }
                </div>
            </Grid>
        </Container>
    );
}

export default withRouter(ChangePassword);