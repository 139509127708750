export const validateClient = (client) => {
    const clientErrors = {};
    const allowedExtensions = /(.jpg|.jpeg|.png)$/i;
    const maxSizeKB = 5000;

    if (client.name === null || client.name.length === 0 ||
        /^\s+$/.test(client.name))
        clientErrors.name = "El nombre de la empresa es requerido";
    else if (client.name.length > 25)
        clientErrors.name = "El nombre de la empresa no debe ser mayor a 25 caracteres.";
    if (client.firstName === null || client.firstName.length === 0 ||
        /^\s+$/.test(client.firstName))
        clientErrors.firstName = "El nombre del administrador es requerido";
    else if (client.firstName.length > 25)
        clientErrors.firstName = "El nombre del administrador no debe ser mayor a 25 caracteres.";
    if (client.lastName === null || client.lastName.length === 0 ||
        /^\s+$/.test(client.lastName))
        clientErrors.lastName = "Los apellidos del administrador son requeridos";
    else if (client.lastName.length > 30)
        clientErrors.lastName = "Los apellidos del administrador no deben ser mayor a 30 caracteres.";
    if (client.email === null || client.email.length === 0 ||
        /^\s+$/.test(client.email))
        clientErrors.email = "El correo del administrador es requerido";
    else if (!validateUserEmail(client.email))
        clientErrors.email = "El formato del correo es incorrecto";

    if (client.numberLicences === null || client.numberLicences.length === 0 ||
        /^\s+$/.test(client.numberLicences))
        clientErrors.numberLicences = "La cantidad de licencias es requerida";
    else if (isNaN(parseFloat(client.numberLicences)) || parseFloat(client.numberLicences) < 1)
        clientErrors.numberLicences = "La cantidad de licencias debe ser un valor númerico y mayor a 0";

    // if (client.languageId === null || client.languageId.length === 0 ||
    //     /^\s+$/.test(client.languageId))
    //     clientErrors.languageId = "El lenguaje principal es requerido";
        
    if (client.image) {
        const size = client.image.size;
        const sizeKB = parseInt(size / 1024);

        if (!allowedExtensions.exec(client.image.name))
            clientErrors.image = 'La extención del logotipo no es válida';
        else if (sizeKB > maxSizeKB)
            clientErrors.image = 'El logotipo no debe superar los 5 MB';
    }
    return clientErrors;
}

const validateUserEmail = ((email) => {
    var regex = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email) ? true : false;
});