import React from 'react';
import { Grid, Typography, Container } from '@mui/material';

const Security = ({ children, logo }) => {
    return (
        <Container>
            <Grid container justifyContent="center" alignItems="center"
                style={{ height: '100vh' }}>
                <Grid item xs={4} style={{ zIndex: 1, paddingLeft: 20 }}>
                    <img src={logo} alt="Logo" style={{ maxHeight: 445, maxWidth: 445 }} />
                </Grid>
                <Grid item xs={8} style={{ paddingRight: 20 }}>
                    <div style={{
                        width: '100%', height: '80vh', maxHeight: 580,
                        borderRadius: 20, backgroundColor: '#FFF',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.05)',
                        marginTop: 60
                    }}>
                        <div style={{
                            height: '100%',
                            padding: '13% 12% 0 25%'
                        }}>
                            {children}
                        </div>
                    </div>
                    <Typography style={{
                        textAlign: 'right',
                        marginTop: 30,
                        width: '100%',
                        paddingRight: 50,
                        fontSize: 14,
                        fontWeight: 'normal',
                        fontStyle: 'normal',
                        lineHeight: '28px',
                        color: '#6C757D'
                    }}>Powered by Softon</Typography>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Security;