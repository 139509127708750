import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';

/**
 * This method is the middleware that is valid if the user can access the path
 * @param {*} params Different parameters that make up a Route
 */
export const ProtectedRoute = ({ component, path, ...rest }) => {
    const reduxToken = useSelector(store => store.auth.token);
    const token = localStorage.getItem('token');
    if ((token && reduxToken) && (token === reduxToken)) {
        return <Route component={component} path={path} {...rest} />
    }
    return <Redirect to={ROUTES.LOGIN} {...rest} />
};
