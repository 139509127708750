import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
    backdrop: {
        zIndex: 10000,
        color: '#FFF',
    },
}));

const Loading = () => {

    const classes = useStyles();
    const authLoading = useSelector(store => store.auth.loading);
    const clientLoading = useSelector(store => store.client.loading);

    const active = () => {
        return !!(authLoading || clientLoading);
    };

    return (
        <Backdrop className={classes.backdrop} open={active()}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}

export default Loading;