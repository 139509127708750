import thunk from 'redux-thunk';
import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import authReducer, { readActiveUserAction } from './AuthDuck';
import clientReducer from './ClientDuck';
import lenguageReducer from './LenguageDuck';

const rootReducer = combineReducers({
    auth: authReducer,
    client: clientReducer,
    lenguage: lenguageReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function generateStore() {
    const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
    readActiveUserAction()(store.dispatch);
    return store;
};