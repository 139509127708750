import React, { useState, useEffect } from 'react';
import { Snackbar } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { clearResponse } from '../../redux/AuthDuck';

const SnackbarResponse = () => {

    const clientResponse = useSelector(store => store.client.response);
    const authResponse = useSelector(store => store.auth.response);

    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const dispatch = useDispatch();

    const handleClose = () => setOpen(false);

    useEffect(() => {
        if (clientResponse || authResponse) {
            setOpen(true);
            setMessage(clientResponse || authResponse);
            setTimeout(() => {
                dispatch(clearResponse())
                setOpen(false);
            }, 5000);
        }
    }, [
        clientResponse,
        authResponse,
        dispatch
    ]);

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={open}
            autoHideDuration={5000}
            onClose={handleClose}
            message={message}
        />
    );
};

export default SnackbarResponse;