import {
    loginURL, forgotPasswordURL,
    resetPasswordURL, getUserURL,
    changePasswordURL
} from '../constants/apiUrls';
import { postAxios, getAxios } from '../services/Axios';
import { appReference } from '../constants/appReference';

// constantes
const initialState = {
    user: null,
    token: null,
    expiration: null,
    isEmailSent: false,
    resetPassword: false,
    isLogged: false,
    response: null,
    loading: false,
    error: null,
};

// types
const USER_SUCCESS = 'USER_SUCCESS';
const TOKEN_SUCCESS = 'TOKEN_SUCCESS';
const PASSWORD_EMAIL_SUCCESS = 'PASSWORD_EMAIL_SUCCESS';
const PASSWORD_SUCCESS = 'PASSWORD_SUCCESS';
const CLEAR_RESPONSE = 'CLEAR_RESPONSE';
const USER_ERROR = 'USER_ERROR';
const LOADING_USER = 'LOADING_USER';
const LOG_OUT = 'LOG_OUT';

// reducer
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOADING_USER:
            return { ...state, loading: true, error: null };
        case TOKEN_SUCCESS:
            return {
                ...state, token: action.payload, expiration: action.expiration,
                error: null, isLogged: true, loading: false
            };
        case USER_SUCCESS:
            return { ...state, user: action.payload, error: null, loading: false };
        case PASSWORD_EMAIL_SUCCESS:
            return {
                ...state, isEmailSent: true, response: action.response,
                error: null, loading: false
            };
        case PASSWORD_SUCCESS:
            return {
                ...state, resetPassword: true, response: action.response,
                error: null, loading: false
            };
        case CLEAR_RESPONSE:
            return { ...state, response: null };
        case USER_ERROR:
            return { ...state, error: action.payload, response: action.payload, loading: false };
        case LOG_OUT:
            return { ...initialState };
        default:
            return state;
    }
};

// actions
export const loginAction = (user) => async (dispatch) => {
    dispatch({ type: LOADING_USER });
    try {
        const res = await postAxios(loginURL, {
            email: user.email,
            password: user.password,
            appReference: appReference
        });
        if (res.status === 200) {
            const token = res.data.response.token;
            const expiration = res.data.response.expiration;
            localStorage.setItem('token', token);
            localStorage.setItem('expiration', expiration);
            return dispatch({
                type: TOKEN_SUCCESS,
                payload: token,
                expiration: expiration
            });
        }
        throw new Error();
    } catch (error) {
        var messageError = 'Invalid password and/or email address.';
        if (error.status === 403)
            messageError = 'Temporary password has expired, click on "forgot your password?" to request a new temporary password.';
        dispatch({
            type: USER_ERROR,
            payload: messageError
        });
    }
};

export const forgotPasswordSendEmailAction = (email) => async (dispatch) => {
    dispatch({ type: LOADING_USER });
    try {
        const res = await postAxios(forgotPasswordURL, {
            email: email,
            appReference: appReference
        });

        if (res.status === 200) {
            return dispatch({
                type: PASSWORD_EMAIL_SUCCESS,
                response: res.data.response
            });
        }
        throw new Error();
    } catch (error) {
        dispatch({
            type: USER_ERROR,
            payload: error.response ? error.response.data.message : 'Email address is not registered in our system.'
        });
    }
};

export const resetPasswordAction = (user) => async (dispatch) => {
    dispatch({ type: LOADING_USER });
    try {
        const res = await postAxios(resetPasswordURL, {
            password: user.password,
            confirmPassword: user.confirmPassword,
            token: user.token,
            email: user.email
        });
        if (res.status === 200) {
            return dispatch({
                type: PASSWORD_SUCCESS,
                response: res.data.response
            });
        }
        throw new Error();
    } catch (error) {
        dispatch({
            type: USER_ERROR,
            payload: error.response ? error.response.data.message : 'Error resetting password, or link has already been used.'
        });
    }
};

export const changePasswordAction = (user) => async (dispatch, getState) => {
    dispatch({ type: LOADING_USER });
    try {
        const email = getState().auth.user.email;
        const res = await postAxios(changePasswordURL, {
            email: email,
            currentPassword: user.currentPassword,
            newPassword: user.password
        });
        if (res.status === 200) {
            dispatch({
                type: PASSWORD_SUCCESS,
                response: res.data.response
            });
            return dispatch(getUser());
        }
        throw new Error();
    } catch (error) {
        dispatch({
            type: USER_ERROR,
            payload: error.response ? error.response.data.message : 'Error resetting password, please verify current password is correct.'

        });
    }
};

export const getUser = () => async (dispatch, getState) => {
    try {
        const { isLogged } = getState().auth;
        if (isLogged) {
            dispatch({ type: LOADING_USER });
            const res = await getAxios(getUserURL);
            if (res.status === 200) {
                const user = res.data.response;
                localStorage.setItem('user-control-fos', JSON.stringify(user));
                return dispatch({
                    type: USER_SUCCESS,
                    payload: user
                });
            }
            throw new Error();
        }
    } catch (error) {
        dispatch({
            type: USER_ERROR,
            payload: error.response ? error.response.data.message : 'Error loading user, please try again.'
        });
    }
}

export const clearError = () => dispatch => {
    dispatch({
        type: USER_ERROR,
        payload: ''
    });
};

export const clearResponse = () => dispatch => {
    dispatch({ type: CLEAR_RESPONSE });
};

export const readActiveUserAction = () => (dispatch) => {
    if (localStorage.getItem('user-control-fos') && localStorage.getItem('token')) {
        dispatch({
            type: USER_SUCCESS,
            payload: JSON.parse(localStorage.getItem('user-control-fos'))
        });
        dispatch({
            type: TOKEN_SUCCESS,
            payload: localStorage.getItem('token'),
            expiration: localStorage.getItem('expiration')
        });
    }
};

export const logoutAction = () => (dispatch) => {
    dispatch({ type: LOG_OUT });
    localStorage.clear();
};