import React, { useState, useEffect } from 'react';
import { Container, Grid, Typography, Button } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { ReactComponent as Search } from '../../../assets/Icons/search.svg';
import { ReactComponent as Filter } from '../../../assets/Icons/filter.svg';
import TextFieldComponent from "../../../components/TextField";
import SelectFieldComponent from '../../../components/SelectField';
import Title from "../../../components/Title";
import * as ROUTES from '../../../constants/routes';
import TableClient from '../Table';
import { Link } from 'react-router-dom'
import { getClients } from '../../../redux/ClientDuck';
import { useDispatch, useSelector } from 'react-redux';

const ReadClient = (props) => {

    const clientList = useSelector(store => store.client.clientList);
    const activePage = useSelector(store => store.client.activePage);
    const [filter, setFilters] = useState('none');
    const [search, setSearch] = useState('');
    const [resetPage, setResetPage] = useState(false);
    const dispatch = useDispatch();

    const handleChange = (event) => {
        setFilters(event.target.value);
        setResetPage(true);
    };
    
    const onChange = (event) => {
        setSearch(event.target.value);
        setResetPage(true);
    };

    useEffect(() => {
        const page = resetPage ? 0 : activePage;
        const timer = setTimeout(() => dispatch(getClients(search, filter, page)), 1000);
        setResetPage(false);
        return () => clearTimeout(timer);
        // eslint-disable-next-line
    }, [dispatch, search, filter, activePage]);

    return (
        <Container>
            <Title title='Clientes' style={{ marginLeft: 5 }} />
            <Grid item xs={12}>
                <div className="container-app-div">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography className="app-form-title">Listado de Clientes</Typography>
                        <Button className='button-app-create' variant="text"
                            startIcon={<AddRoundedIcon />}
                            component={Link} to={ROUTES.CLIENT_CREATE}
                        >
                            Crear Cliente
                        </Button>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ width: '35%' }}>
                            <TextFieldComponent
                                Icon={<Search />}
                                position='start'
                                placeholder='Buscar'
                                valueInput={search}
                                callback={onChange}
                            />
                        </div>
                        <div>
                            <SelectFieldComponent name="filter"
                                placeholder="Filtrar por estado"
                                valueInput={filter}
                                onChange={handleChange}
                                options={[
                                    { key: 'false', value: 'Activo' },
                                    { key: 'true', value: 'Archivada' }
                                ]}
                                Icon={Filter}
                            />
                        </div>
                    </div>
                    <TableClient clientList={clientList} />
                </div>
            </Grid>
        </Container>
    )
};

export default ReadClient;