import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Typography, InputLabel, Button } from '@mui/material';
import Security from '../../components/Security';
import TextFieldComponent from '../../components/TextField';
import Logo from '../../assets/Images/ResetPassword.png';
import { resetPasswordAction } from '../../redux/AuthDuck';
import { useDispatch, useSelector } from 'react-redux';
import * as ROUTES from '../../constants/routes';
import './ResetPassword.css';

const queryString = require('query-string');

const ResetPassword = props => {

    const resetPassword = useSelector(store => store.auth.resetPassword);
    const error = useSelector(store => store.auth.error);
    const [password, setPassword] = useState({
        password: '', confirmPassword: '', token: '', email: ''
    });
    const [passwordError, setPasswordError] = useState({});
    const dispatch = useDispatch();

    const onChange = e => {
        const { name, value } = e.target;
        setPassword({ ...password, [name]: value });
    };

    const onSubmit = () => {
        if (validationPassword()) {
            dispatch(resetPasswordAction(password));
        }
    };

    const validationPassword = () => {
        const passwordLength = 8;
        var error = { password: '', confirmPassword: '' };

        if (password.password === null || password.password.length === 0
            || /^\s+$/.test(password.password))
            error.password = "The password is required.";
        else if (password.password.length < passwordLength ||
            !/[^a-zA-Z0-9]/.test(password.password))
            error.password = "Invalid password format, at least 8 characters required, and these must include numbers, upper case characters, lower case characters and at least 1 special character.";
        if (password.confirmPassword === null || password.confirmPassword.length === 0
            || /^\s+$/.test(password.confirmPassword))
            error.confirmPassword = "The password is required.";
        else if (password.confirmPassword.length < passwordLength ||
            !/[^a-zA-Z0-9]/.test(password.confirmPassword))
            error.confirmPassword = "Invalid password format, at least 8 characters required, and these must include numbers, upper case characters, lower case characters and at least 1 special character.";
        if ((!error.password && !error.confirmPassword) &&
            password.password !== password.confirmPassword)
            error.confirmPassword = "Passwords do not match.";

        setPasswordError({ ...passwordError, ...error });
        return !error.password && !error.confirmPassword;
    };

    useEffect(() => {
        const parsed = queryString.parse(props.location.search);
        if (parsed.email && parsed.token)
            setPassword({
                ...password,
                token: parsed.token.replaceAll(' ', '+'),
                email: parsed.email
            });
        // eslint-disable-next-line
    }, [props.location.search]);

    useEffect(() => {
        resetPassword && props.history.push(ROUTES.LOGIN);
    }, [resetPassword, props.history]);

    useEffect(() => {
        setPasswordError({ confirmPassword: error });
    }, [error]);

    return (
        <Security logo={Logo}>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <form>
                    <Typography className="title-app" style={{ marginBottom: '5%' }}>
                        Create a new password
                    </Typography>
                    <Typography className="label-reset-password" style={{ marginBottom: '5%' }}>
                        Create a new and safe password that you do not use in other websites 
                    </Typography>
                    <InputLabel className="label-app">New Password</InputLabel>
                    <TextFieldComponent name="password"
                        valueInput={password.password} type="password"
                        callback={onChange.bind(this)}
                        errorInput={passwordError.password}
                    />
                    <InputLabel className="label-app">Confirm Password</InputLabel>
                    <TextFieldComponent name="confirmPassword"
                        valueInput={password.confirmPassword} type="password"
                        callback={onChange.bind(this)}
                        errorInput={passwordError.confirmPassword}
                    />
                    <Button variant="contained" className="button-reset-password"
                        disableElevation onClick={() => onSubmit()}>
                        Submit
                    </Button>
                </form>
            </div>
        </Security>
    );
}

export default withRouter(ResetPassword);