// Base URL
 const baseURL = 'https://fos-dev.softonitg.com/api/';
//const baseURL = 'http://52.203.7.205:8080/api/';

// Auth URLs
export const loginURL = `${baseURL}Authenticate/login`;
export const forgotPasswordURL = `${baseURL}Authenticate/forgotPassword`;
export const resetPasswordURL = `${baseURL}Authenticate/resetPassword`;
export const getUserURL = `${baseURL}User/currentUser`;
export const changePasswordURL = `${baseURL}User/changePassword`;

export const getOrganizationsURL = `${baseURL}OrganizationSetting`;
export const getOrganizationIdURL = `${baseURL}OrganizationSetting/with-admin`;
export const getOrganizationDetailURL = `${baseURL}OrganizationSetting/detail`;
export const getOrganizationDisableURL = `${baseURL}OrganizationSetting/disable?id=`;
export const getOrganizationActiveURL = `${baseURL}OrganizationSetting/activate?id=`;
export const postOrganizationsURL = `${baseURL}OrganizationSetting/create`;
export const putOrganizationsURL = `${baseURL}OrganizationSetting/edit`;

export const getAppSettingByIdURL = `${baseURL}AppSetting/id`;
export const putAppSettingURL = `${baseURL}AppSetting/edit`;

export const getLenguageURL = `${baseURL}Language`;
export const getFileURL = `${baseURL}File/preview?id=`;
