import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    Container, CardMedia, Grid, Typography, InputLabel,
    TableContainer, Table, TableHead, TableRow,
    TableCell, TableBody, Button
} from '@mui/material';
import { Link } from 'react-router-dom';
import CircleIcon from '@mui/icons-material/Circle';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { ReactComponent as CheckCircle } from '../../../assets/Icons/checkCircle.svg';
import { ReactComponent as DisableCircle } from '../../../assets/Icons/disableCircle.svg';
import TextFieldComponent from '../../../components/TextField';
import * as ROUTES from '../../../constants/routes';
import { getClientById, disableClient, getAppSettingById, updateAppSetting } from '../../../redux/ClientDuck';
import { getFileURL } from '../../../constants/apiUrls';
import './Detail.css';

const DetailClient = props => {

    const company = useSelector(store => store.client.company);
    const appSetting = useSelector(store => store.client.appSetting);
    const [error, setError] = useState({});
    const [url, setUrl] = useState({
        url_android: '', url_ios: ''
    });
    const [keys, setKeys] = useState({
        sender_id: '', server_key: ''
    });

    const onChange = e => {
        const { name, value } = e.target;
        setUrl({ ...url, [name]: value });
    };
    const onChangeKeys = e => {
        const { name, value } = e.target;
        setKeys({ ...keys, [name]: value });
    };

    const dispatch = useDispatch();

    const dateFormat = () => {
        const a = new Date(company.created)
        return a.toLocaleString("es-CR", { day: 'numeric', month: 'long', year: 'numeric' });
    };

    const validationAppKeys = () => {
        var errors = { sender_id: '', server_key: '' };

        if (keys.sender_id === null || keys.sender_id.length === 0 ||
            /^\s+$/.test(keys.sender_id))
            errors.sender_id = "Sender Id requerida.";
        
        if (keys.server_key === null || keys.server_key.length === 0 ||
            /^\s+$/.test(keys.server_key))
            errors.server_key = "Server Key requerida.";
        
        setError({ ...error, ...errors });
        return !errors.sender_id && !errors.server_key;
    };
    const validationAppConfig = () => {
        var errors = { url_android: '', url_ios: '' };

        if (url.url_android === null || url.url_android.length === 0 ||
            /^\s+$/.test(url.url_android))
            errors.url_android = "Url requerida.";
        else if (!isValidURL(url.url_android))
            errors.url_android = "La url es inválida.";

        if (url.url_ios === null || url.url_ios.length === 0 ||
            /^\s+$/.test(url.url_ios))
            errors.url_ios = "Url requerida.";
        else if (!isValidURL(url.url_ios))
            errors.url_ios = "La url es inválida.";

        setError({ ...error, ...errors });
        return !errors.url_ios && !errors.url_android;
    };

    const isValidURL = (url) => {
        const regExp = /^(ftp|http|https):\/\/[^ "]+$/;
        return regExp.test(url) ? true : false;
    };

    const modifyAppSetting = () => {
        if (validationAppConfig()) {
            dispatch(updateAppSetting(url));
        }
    };

    const modifyAppSettingKeys = () => {
       // console.log(keys);
        if (validationAppKeys()) {
            dispatch(updateAppSetting(keys));
        }
    };

    useEffect(() => {
        const { id } = props.match.params;
        dispatch(getClientById(id));
    }, [dispatch, props.match.params]);

    useEffect(() => {
        if (company && company.appSettingId) {
            dispatch(getAppSettingById(company.appSettingId));
        }
    }, [dispatch, company]);

    useEffect(() => {
        if (appSetting) {
            setUrl({
                url_android: appSetting.googlePlayLink ? appSetting.googlePlayLink : '',
                url_ios: appSetting.appStoreLink ? appSetting.appStoreLink : ''
            });
        }
    }, [appSetting]);

    useEffect(() => {
        console.log(appSetting);
        if (appSetting) {
            setKeys({
                sender_id: appSetting.senderId ? appSetting.senderId : '',
                server_key: appSetting.serverKey ? appSetting.serverKey : ''
            });
        }
    }, [appSetting]);

    return company && company.id ? (
        <Container>
            <Button component={Link} to={ROUTES.CLIENT}
                underline="none"
                className='button-back'>
                <ArrowBackIosIcon />
                {company.name}
            </Button>
            <Grid item xs={12}>
                <div className="container-app-div">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ display: 'flex' }}>
                            {company.fileId &&
                                <CardMedia component="img"
                                    alt={company.name}
                                    image={getFileURL + company.fileId}
                                    sx={{
                                        width: 153, height: 86
                                    }}
                                />
                            }
                            <div style={{ marginLeft: 37 }}>
                                <Typography className="company-name" style={{ margin: '4px 0px' }}>
                                    {company.name}
                                </Typography>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    color: company.disabled ? '#6C757D' : '#28A745',
                                    margin: '9px 0px'
                                }}>
                                    <CircleIcon style={{ fontSize: 7, marginRight: 7 }} />
                                    <Typography className='company-status'>
                                        {company.disabled ? 'Archivado' : 'Activo'}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-app-div">
                    <Typography className="app-form-title">
                        Detalles de la Empresa
                    </Typography>
                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow className="header-cell">
                                    <TableCell className="detail-table-cell" align="right">Descripción</TableCell>
                                    <TableCell sx={{ width: '78%' }} className="detail-table-cell">Detalle</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow key={'id'} className="row-org-detail">
                                    <TableCell component="th" scope="row" className="description-table-cell" >
                                        Empresa
                                    </TableCell>
                                    <TableCell className="detail-table-cell">
                                        {company.name}
                                    </TableCell>
                                </TableRow>
                                <TableRow key={'id10'} className="row-org-detail">
                                    <TableCell component="th" scope="row" className="description-table-cell">
                                        ID 
                                    </TableCell>
                                    <TableCell className="detail-table-cell">
                                        {company.id}
                                    </TableCell>
                                </TableRow>
                                <TableRow key={'id2'} className="row-org-detail">
                                    <TableCell component="th" scope="row" className="description-table-cell">
                                        Estado
                                    </TableCell>
                                    <TableCell className="detail-table-cell">
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            color: company.disabled ? '#6C757D' : '#28A745',
                                        }}>
                                            <CircleIcon style={{ fontSize: 7, marginRight: 7 }} />
                                            <Typography>{company.disabled ? 'Archivado' : 'Activo'}</Typography>
                                        </div>
                                    </TableCell>
                                </TableRow>
                                <TableRow key={'id3'} className="row-org-detail">
                                    <TableCell component="th" scope="row" className="description-table-cell">
                                        Licencias
                                    </TableCell>
                                    <TableCell className="detail-table-cell">
                                        {company.numberOfLicense} Licencias
                                    </TableCell>
                                </TableRow>
                                <TableRow key={'id4'} className="row-org-detail">
                                    <TableCell component="th" scope="row" className="description-table-cell">
                                        Usuarios
                                    </TableCell>
                                    <TableCell className="detail-table-cell">
                                        {company.usersCount} Usuarios Internos Activos
                                    </TableCell>
                                </TableRow>
                                <TableRow key={'id5'} className="row-org-detail">
                                    <TableCell component="th" scope="row" className="description-table-cell">
                                        Clientes
                                    </TableCell>
                                    <TableCell className="detail-table-cell">
                                        {company.clientsCount} Clientes
                                    </TableCell>
                                </TableRow>
                                <TableRow key={'id6'} className="row-org-detail">
                                    <TableCell component="th" scope="row" className="description-table-cell">
                                        Personal del cliente
                                    </TableCell>
                                    <TableCell className="detail-table-cell">
                                        {company.clientStaffCount} Usuarios
                                    </TableCell>
                                </TableRow>
                                <TableRow key={'id7'} className="row-org-detail">
                                    <TableCell component="th" scope="row" className="description-table-cell">
                                        Administrador
                                    </TableCell>
                                    <TableCell className="detail-table-cell" style={{
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }}>
                                        <label>
                                            {company.users && company.users[0].firstName + ' ' + company.users[0].lastName}
                                        </label>
                                        <label>
                                            {company.users && company.users[0].email}
                                        </label>
                                    </TableCell>
                                </TableRow>
                                <TableRow key={'id8'} className="row-org-detail">
                                    <TableCell component="th" scope="row" className="description-table-cell">
                                        Creado
                                    </TableCell>
                                    <TableCell className="detail-table-cell">
                                        {dateFormat()}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div className="container-app-div">
                    <Typography className="app-form-title">
                        Licencias
                    </Typography>
                    <div style={{ width: '48%', marginTop: 24 }}>
                        <InputLabel className="label-app">Cantidad de licencias</InputLabel>
                        <TextFieldComponent name="numberOfLicense"
                            callback={() => null} disabled
                            valueInput={company.numberOfLicense}
                        />
                    </div>
                </div>
                {company.appSettingId &&
                    <div className="container-app-div">
                        <Typography className="app-form-title">
                            Enlaces
                        </Typography>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ width: '48%' }}>
                                <InputLabel className="label-app">Android</InputLabel>
                                <TextFieldComponent name="url_android"
                                    callback={onChange.bind(this)}
                                    valueInput={url.url_android}
                                    placeholder='Url Android'
                                    errorInput={error.url_android}
                                />
                            </div>
                            <div style={{ width: '48%' }}>
                                <InputLabel className="label-app">iOS</InputLabel>
                                <TextFieldComponent name="url_ios"
                                    callback={onChange.bind(this)}
                                    valueInput={url.url_ios}
                                    placeholder='Url iOS'
                                    errorInput={error.url_ios}
                                />
                            </div>
                        </div>
                        <div style={{ display: 'flex', marginTop: 15 }}>
                            <Button variant="contained" className="button-app-save" style={{ marginRight: 18 }}
                                onClick={() => modifyAppSetting()}>
                                Enviar
                            </Button>
                        </div>
                    </div>
                }
                {company.appSettingId &&
                    <div className="container-app-div">
                        <Typography className="app-form-title">
                        Firebase App keys 
                        </Typography>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ width: '48%' }}>
                                <InputLabel className="label-app">Sender Id</InputLabel>
                                <TextFieldComponent name="sender_id"
                                    callback={onChangeKeys.bind(this)}
                                    valueInput={keys.sender_id}
                                    placeholder='sender ID'
                                    errorInput={error.sender_id}
                                />
                            </div>
                            <div style={{ width: '48%' }}>
                                <InputLabel className="label-app">Server Key</InputLabel>
                                <TextFieldComponent name="server_key"
                                    callback={onChangeKeys.bind(this)}
                                    valueInput={keys.server_key}
                                    maxLength="200"
                                    row="4"
                                    placeholder='server Key'
                                    errorInput={error.server_key}
                                />
                            </div>
                        </div>
                        <div style={{ display: 'flex', marginTop: 15 }}>
                            <Button variant="contained" className="button-app-save" style={{ marginRight: 18 }}
                                onClick={() => modifyAppSettingKeys()}>
                                Enviar 
                            </Button>
                        </div>
                    </div>
                }
                <div className="container-app-div">
                    <Typography className="app-form-title">
                        Opciones
                    </Typography>
                    <Button className='button-active-inactive' variant="text"
                        startIcon={company.disabled ? <CheckCircle /> : <DisableCircle />}
                        onClick={() => dispatch(disableClient(company.id))}
                    >
                        {company.disabled ? 'Activar' : 'Suspender'}
                    </Button>
                </div>
            </Grid>
        </Container>
    ) : null;
}

export default withRouter(DetailClient);