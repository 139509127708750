import React, { useEffect } from 'react';
import Form from '../Form';
import { useSelector, useDispatch } from 'react-redux';
import { changeIsCreating } from '../../../redux/ClientDuck';

const CreateClient = () => {

    const isCreating = useSelector(store => store.client.isCreating);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(changeIsCreating(true));
    }, [dispatch]);

    return isCreating && (
        <Form />
    )
};

export default CreateClient;