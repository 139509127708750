import {
    getOrganizationsURL, postOrganizationsURL,
    getOrganizationIdURL, getOrganizationDetailURL,
    getOrganizationDisableURL, getOrganizationActiveURL, getAppSettingByIdURL, putAppSettingURL
} from '../constants/apiUrls';
import { postAxios, putAxios, getAxios } from '../services/Axios';

// constantes
const initialState = {
    clientList: [],
    company: {},
    appSetting: {},
    isCreating: true,
    isCreated: false,
    loading: false,
    error: null,
    pages: 1,
    activePage: 0
};

// types
const GET_CLIENTS_SUCCESS = 'GET_CLIENTS_SUCCESS';
const GET_CLIENT_BY_ID_SUCCESS = 'GET_CLIENT_BY_ID_SUCCESS';
const CREATE_UPDATE_CLIENT_SUCCESS = 'CREATE_UPDATE_CLIENT_SUCCESS';
const CLIENT_STATUS_CHANGE = 'CLIENT_STATUS_CHANGE';
const IS_CREATING_CLIENT = 'IS_CREATING_CLIENT';
const CLIENT_CHANGE_PAGE = 'CLIENT_CHANGE_PAGE';
const CLIENT_ERROR = 'CLIENT_ERROR';
const CLIENT_LOADING = 'CLIENT_LOADING';
const CLEAR_RESPONSE = 'CLEAR_RESPONSE';
const APP_SUCCESS = 'APP_SUCCESS';
const APP_SUCCESS_SAVE = 'APP_SUCCESS_SAVE';
const LOG_OUT = 'LOG_OUT';

// reducer
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case CLIENT_LOADING:
            return { ...state, loading: true, error: null };
        case GET_CLIENTS_SUCCESS:
            return {
                ...state, clientList: action.list, activePage: action.activePage,
                pages: action.pages, loading: false, error: null, appSetting: null,
                company: null
            };
        case GET_CLIENT_BY_ID_SUCCESS:
            return { ...state, company: action.payload, error: null, loading: false }
        case CREATE_UPDATE_CLIENT_SUCCESS:
            return {
                ...state, isCreated: true, response: action.response,
                activePage: 0, loading: false, error: null
            };
        case CLIENT_STATUS_CHANGE:
            return { ...state, response: action.response, loading: false, error: null };
        case CLIENT_CHANGE_PAGE:
            return { ...state, activePage: action.activePage };
        case IS_CREATING_CLIENT:
            return { ...state, isCreating: action.payload, isCreated: false };
        case APP_SUCCESS:
            return {
                ...state, appSetting: action.payload, error: null, loading: false
            };
        case APP_SUCCESS_SAVE:
            return { ...state, response: action.response };
        case CLIENT_ERROR:
            return { ...state, error: action.payload, response: action.payload, loading: false };
        case CLEAR_RESPONSE:
            return { ...state, response: null };
        case LOG_OUT:
            return { ...initialState };
        default:
            return state;
    }
};

// actions
export const getClients = (search = '', state = '', page = 0) => async (dispatch) => {
    dispatch({ type: CLIENT_LOADING });
    try {
        const clientList = [];
        const filterBy = [];
        search !== '' && filterBy.push({ search: search, filterBy: 'search-bar' });
        state !== 'none' && filterBy.push({ search: state, filterBy: 'disabled' });
        const res = await postAxios(getOrganizationsURL, {
            "page": page,
            "pageSize": 10,
            "filterBy": filterBy,
            "orderBy": "",
            "orderDesc": true
        });
        const clientsResponse = res.data.response;
        clientsResponse &&
            clientsResponse.forEach(item => {
                clientList.push({
                    ...convertAPIClientObject(item)
                });
            });
        dispatch({
            type: GET_CLIENTS_SUCCESS,
            list: clientList,
            pages: res.data.totalPages,
            activePage: page
        });
    } catch (error) {
        dispatch({
            type: CLIENT_ERROR,
            payload: error.response ? error.response.data.message : 'Error al cargar las organizaciones'
        });
    }
};

export const changePage = (page) => (dispatch) => {
    dispatch({
        type: CLIENT_CHANGE_PAGE,
        activePage: page - 1
    });
};

const convertAPIClientObject = (client) => {
    return {
        'id': client.id,
        'name': client.name,
        'user': client.users.length > 0 ? client.users[0] : {},
        'numberOfLicense': client.numberOfLicense,
        'disabled': client.disabled
    };
};

export const getClientById = (id) => async (dispatch) => {
    dispatch({ type: CLIENT_LOADING });
    try {
        const res = await getAxios(getOrganizationIdURL, { id: id });
        const resDetail = await getAxios(getOrganizationDetailURL, { id: id });
        if (res.status === 200 && resDetail.status === 200) {
            return dispatch({
                type: GET_CLIENT_BY_ID_SUCCESS,
                payload: {
                    ...res.data.response,
                    'usersCount': resDetail.data.response.users,
                    'clientsCount': resDetail.data.response.clients,
                    'clientStaffCount': resDetail.data.response.clientStaff,
                }
            });
        }
        throw new Error();
    } catch (error) {
        dispatch({
            type: CLIENT_ERROR,
            payload: error.response ? error.response.data.message : 'Error al crear la organización.'
        });
    }
};

export const createClient = (client) => async (dispatch) => {
    dispatch({ type: CLIENT_LOADING });
    try {
        const bodyFormData = new FormData();
        bodyFormData.append('logo', client.image ? client.image : null);
        bodyFormData.append('name', client.name);
        bodyFormData.append('firstName', client.firstName);
        bodyFormData.append('lastName', client.lastName);
        bodyFormData.append('email', client.email);
        bodyFormData.append('numberOfLicense', client.numberLicences);
        // bodyFormData.append('defaultLanguageId', client.languageId);
        const res = await postAxios(postOrganizationsURL, bodyFormData);
        if (res.status === 200) {
            return dispatch({
                type: CREATE_UPDATE_CLIENT_SUCCESS,
                response: res.data.response
            });
        }
        throw new Error();
    } catch (error) {
        dispatch({
            type: CLIENT_ERROR,
            payload: error.response ? error.response.data.message : 'Error al crear la organización.'
        });
    }
}

export const disableClient = (id) => async (dispatch, getState) => {
    dispatch({ type: CLIENT_LOADING });
    try {
        const { company } = getState().client;
        const res = !company.disabled
            ? await putAxios(getOrganizationDisableURL + id)
            : await putAxios(getOrganizationActiveURL + id);
        if (res.status === 200) {
            dispatch({
                type: CLIENT_STATUS_CHANGE,
                response: res.data.response
            });
            return dispatch(getClientById(id));
        }
        throw new Error();
    } catch (error) {
        dispatch({
            type: CLIENT_ERROR,
            payload: error.response ? error.response.data.message : 'Error al activar/archivar la organización.'
        });
    }
};

export const changeIsCreating = (state) => (dispatch) => {
    dispatch({
        type: IS_CREATING_CLIENT,
        payload: state
    });
};

export const getAppSettingById = (appSettingId) => async (dispatch, getState) => {
    try {
        const res = await getAxios(getAppSettingByIdURL, { id: appSettingId });
        if (res.status === 200) {
            return dispatch({
                type: APP_SUCCESS,
                payload: res.data.response
            });
        }
        throw new Error();
    } catch (error) {
        dispatch({
            type: CLIENT_ERROR,
            payload: error.response ? error.response.data.message : 'Error al obtener la configuracion movil.'
        });
    }
};

export const updateAppSetting = (appSetting) => async (dispatch, getState) => {
    dispatch({ type: CLIENT_LOADING });
    try {
        const appSettingRedux = getState().client.appSetting;
        const res = await putAxios(putAppSettingURL, {
            'id': appSettingRedux.id,
            'secundaryLanguageId': parseInt(appSettingRedux.secundaryLanguageId),
            'appStoreLink': appSetting.url_ios,
            'googlePlayLink': appSetting.url_android,
            'senderId': appSetting.sender_id,
            'serverKey': appSetting.server_key
        });

        if (res.status === 200) {
            dispatch({
                type: APP_SUCCESS_SAVE,
                response: res.data.response
            })
            return dispatch(getAppSettingById(appSettingRedux.id));
        }
        throw new Error();
    } catch (error) {
        dispatch({
            type: CLIENT_ERROR,
            payload: error.response ? error.response.data.message : 'Error al crear la configuracion movil'
        });
    }
};