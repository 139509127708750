import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Pagination } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { changePage } from '../../../redux/ClientDuck';
import './Pagination.css';

const CustomerPagination = () => {

    const pages = useSelector(store => store.client.pages);
    const activePage = useSelector(store => store.client.activePage);
    const dispatch = useDispatch();
    
    return (
        <Pagination
            count={pages}
            page={activePage + 1}
            padding={5}
            onChange={(e, page) => dispatch(changePage(page))}
            shape="rounded"
            translations={{
                previous: <ArrowBackIos fontSize='small' />,
                next: <ArrowForwardIos fontSize='small' />,
            }}
        />
    );
};

export default CustomerPagination;