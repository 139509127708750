import { getLenguageURL } from '../constants/apiUrls';
import { getAxios } from '../services/Axios';

// constantes
const initialState = {
    lenguages: [],
    loading: false,
    error: null,
};

// types
const LENGUAGE_SUCCESS = 'LENGUAGE_SUCCESS';
const LENGUAGE_ERROR = 'LENGUAGE_ERROR';
const LOADING_LENGUAGE = 'LOADING_LENGUAGE';
const LOG_OUT = 'LOG_OUT';

// reducer
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOADING_LENGUAGE:
            return { ...state, loading: true, error: null };
        case LENGUAGE_SUCCESS:
            return { ...state, lenguages: action.payload, error: null, loading: false };
        case LENGUAGE_ERROR:
            return { ...state, error: action.payload, loading: false };
        case LOG_OUT:
            return { ...initialState };
        default:
            return state;
    }
};

export const getLenguages = () => async (dispatch, getState) => {
    dispatch({ type: LOADING_LENGUAGE });
    try {
        const res = await getAxios(getLenguageURL);
        if (res.status === 200) {
            const lenguages = res.data.response;
            const list = [];
            lenguages.forEach(item => {
                list.push({
                    key: item.id,
                    value: item.languageName,
                })
            });
            return dispatch({
                type: LENGUAGE_SUCCESS,
                payload: list
            });
        }
        throw new Error();
    } catch (error) {
        dispatch({
            type: LENGUAGE_ERROR,
            payload: 'Error al cargar los lenguajes.'
        });
    }
};